<template>
  <div>
    <b-row>
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Account</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_user"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <!--      <b-col cols="3">-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Account</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_account"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Active Account</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_account_activated"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Collection</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_collection"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Bill</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_bills"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->
      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Bill Paid</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_bill_paid"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <!--      <b-col-->
      <!--        cols="12"-->
      <!--        lg="3"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <b-card body-class="pb-50">-->
      <!--            <h6>Total Bill Pending</h6>-->
      <!--            <h2 class="font-weight-bolder mb-1">-->
      <!--              <vue-numeric-->
      <!--                read-only-->
      <!--                :precision="0"-->
      <!--                separator=","-->
      <!--                :value="DashboardData.total_bill_pending"-->
      <!--              />-->
      <!--            </h2>-->
      <!--            <div style="min-height: 55px" />-->
      <!--          </b-card>-->
      <!--        </div>-->
      <!--      </b-col>-->

      <!--      &lt;!&ndash;      <b-col cols="3">&ndash;&gt;-->
      <!--      &lt;!&ndash;        <div>&ndash;&gt;-->
      <!--      &lt;!&ndash;          <b-card body-class="pb-50">&ndash;&gt;-->
      <!--      &lt;!&ndash;            <h6>Total Onboard</h6>&ndash;&gt;-->
      <!--      &lt;!&ndash;            <h2 class="font-weight-bolder mb-1">&ndash;&gt;-->
      <!--      &lt;!&ndash;              <vue-numeric&ndash;&gt;-->
      <!--      &lt;!&ndash;                read-only&ndash;&gt;-->
      <!--      &lt;!&ndash;                :precision="0"&ndash;&gt;-->
      <!--      &lt;!&ndash;                separator=","&ndash;&gt;-->
      <!--      &lt;!&ndash;                :value="DashboardData.total_account_onboard"&ndash;&gt;-->
      <!--      &lt;!&ndash;              />&ndash;&gt;-->
      <!--      &lt;!&ndash;            </h2>&ndash;&gt;-->
      <!--      &lt;!&ndash;            <div style="min-height: 55px" />&ndash;&gt;-->
      <!--      &lt;!&ndash;          </b-card>&ndash;&gt;-->
      <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      &lt;!&ndash;      </b-col>&ndash;&gt;-->

      <b-col
        cols="12"
        lg="6"
      >
        <div>
          <b-card body-class="pb-50">
            <h6>Successful Collection (Today)</h6>
            <h2 class="font-weight-bolder mb-1">
              <vue-numeric
                read-only
                :precision="2"
                separator=","
                :currency="myCurrency"
                :value="DashboardData.all_time_successful_collection"
              />
            </h2>
            <div style="min-height: 55px" />
          </b-card>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <div>
          <b-card body-class="pb-50">
            <h6>Successful Payout (Today)</h6>
            <h2 class="font-weight-bolder mb-1">
              <vue-numeric
                read-only
                :precision="2"
                separator=","
                :currency="myCurrency"
                :value="DashboardData.all_time_successful_payout"
              />
            </h2>
            <div style="min-height: 55px" />
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <h6>Pending Bill</h6>

              <h2
                class="font-weight-bolder mb-1"
                :class="crmBillDashboardData.pending_count < 150 ? '' : 'text-danger'"
              >
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.pending_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Success Bill</h6>
                <div style="margin-top: -2px">
                  SR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="billSuccessRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.success_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Failed Bill</h6>
                <div style="margin-top: -2px">
                  FR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="billFailRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmBillDashboardData.failed_count"
                />
              </h2>
            </b-card>
          </b-col>
        </b-row>
        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <b-card no-body>-->
        <!--              <div class="d-flex justify-content-between align-items-center px-2 py-1">-->

        <!--                <h5 class="mb-0">-->
        <!--                  Recent Bill-->
        <!--                </h5>-->

        <!--                <b-button-->
        <!--                  size="sm"-->
        <!--                  @click="reloadBillTable"-->
        <!--                >-->
        <!--                  Reload-->
        <!--                </b-button>-->
        <!--              </div>-->

        <!--              <b-table-->
        <!--                show-empty-->
        <!--                responsive-->
        <!--                :fields="tableHeader"-->
        <!--                :items="adminRecentBillsMinuteData"-->
        <!--              >-->
        <!--                <template #empty>-->
        <!--                  <div class="p-2 text-center">-->
        <!--                    No Records-->
        <!--                  </div>-->
        <!--                </template>-->
        <!--                <template #cell(avg_time_to_success_seconds)="row">-->
        <!--                  <vue-numeric-->
        <!--                    read-only-->
        <!--                    separator=","-->
        <!--                    :precision="2"-->
        <!--                    :value="row.item.avg_time_to_success_seconds || 0"-->
        <!--                  />-->
        <!--                </template>-->

        <!--                <template #cell(combined_count)="row">-->
        <!--                  <span class="text-success">{{ row.item.success_count }}</span> / <span class="text-warning">{{ row.item.pending_count }}</span> / <span class="text-danger">{{ row.item.failed_count }}</span>-->
        <!--                </template>-->
        <!--              </b-table>-->
        <!--            </b-card>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <h6>Pending Payout</h6>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  :class="crmPayoutDashboardData.pending_count < 150 ? '' : 'text-danger'"
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.pending_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>
                  Success Payout
                </h6>
                <div style="margin-top: -2px">
                  SR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="payoutSuccessRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.success_count"
                />
              </h2>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <b-card
              body-class="border border-primary pb-50"
            >
              <div class="d-flex justify-content-between">
                <h6>Failed Payout</h6>
                <div style="margin-top: -2px">
                  FR
                  <vue-numeric
                    read-only
                    separator=","
                    :precision="2"
                    currency="%"
                    currency-symbol-position="suffix"
                    :value="payoutFailRate"
                  />
                </div>
              </div>

              <h2 class="font-weight-bolder mb-1">
                <vue-numeric
                  read-only
                  separator=","
                  :value="crmPayoutDashboardData.failed_count"
                />
              </h2>
            </b-card>
          </b-col>
        </b-row>

        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <b-card-->
        <!--              no-body-->
        <!--            >-->
        <!--              <div class="d-flex justify-content-between align-items-center px-2 py-1">-->

        <!--                <h5 class="mb-0">-->
        <!--                  Recent Payout-->
        <!--                </h5>-->

        <!--                <b-button-->
        <!--                  size="sm"-->
        <!--                  @click="reloadPayoutTable"-->
        <!--                >-->
        <!--                  Reload-->
        <!--                </b-button>-->
        <!--              </div>-->
        <!--              <b-table-->
        <!--                responsive-->
        <!--                show-empty-->
        <!--                :fields="tableHeader"-->
        <!--                :items="adminRecentPayoutMinuteData"-->
        <!--              >-->
        <!--                <template #empty>-->
        <!--                  <div class="p-2 text-center">-->
        <!--                    No Records-->
        <!--                  </div>-->
        <!--                </template>-->
        <!--                <template #cell(avg_time_to_success_seconds)="row">-->
        <!--                  <vue-numeric-->
        <!--                    read-only-->
        <!--                    separator=","-->
        <!--                    :precision="2"-->
        <!--                    :value="row.item.avg_time_to_success_seconds || 0"-->
        <!--                  />-->
        <!--                </template>-->

        <!--                <template #cell(combined_count)="row">-->
        <!--                  <span class="text-success">{{ row.item.success_count }}</span> / <span class="text-warning">{{ row.item.pending_count }}</span> / <span class="text-danger">{{ row.item.failed_count }}</span>-->
        <!--                </template>-->
        <!--              </b-table>-->
        <!--            </b-card>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-col>
    </b-row>
    <!--    <b-row class="d-none d-lg-block align-self-stretch">-->
    <!--      <b-col-->
    <!--        cols="12"-->
    <!--        lg="12"-->
    <!--      >-->
    <!--        <b-card title="Bill">-->
    <!--          <chartjs-component-bar-chart-->
    <!--            class="mt-3"-->
    <!--            :height="120"-->
    <!--            :chart-data="chartBillPaid"-->
    <!--            :options="chartjsData.latestBarChart.options2"-->
    <!--          />-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--      &lt;!&ndash;      <b-col cols="4">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <b-card title="Bill">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <chartjs-component-doughnut-chart&ndash;&gt;-->
    <!--      &lt;!&ndash;            :height="400"&ndash;&gt;-->
    <!--      &lt;!&ndash;            :data="chartBillPaidDonut"&ndash;&gt;-->
    <!--      &lt;!&ndash;            :options="chartjsData.doughnutChart.options"&ndash;&gt;-->
    <!--      &lt;!&ndash;            class="mb-3"&ndash;&gt;-->
    <!--      &lt;!&ndash;          />&ndash;&gt;-->
    <!--      &lt;!&ndash;        </b-card>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </b-col>&ndash;&gt;-->
    <!--    </b-row>-->
    <!--    <b-row class="d-none d-lg-block">-->
    <!--      <b-col-->
    <!--        cols="12"-->
    <!--        lg="12"-->
    <!--      >-->
    <!--        <b-card title="Account">-->
    <!--          <chartjs-component-bar-chart-->
    <!--            class="mt-3"-->
    <!--            :height="120"-->
    <!--            :chart-data="chartUsers"-->
    <!--            :options="chartjsData.latestBarChart.options3"-->
    <!--          />-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--      &lt;!&ndash;      <b-col cols="4">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <b-card title="Account">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <chartjs-component-doughnut-chart&ndash;&gt;-->
    <!--      &lt;!&ndash;            :height="400"&ndash;&gt;-->
    <!--      &lt;!&ndash;            :data="chartUsersDonut"&ndash;&gt;-->
    <!--      &lt;!&ndash;            :options="chartjsData.doughnutChart.options"&ndash;&gt;-->
    <!--      &lt;!&ndash;            class="mb-3"&ndash;&gt;-->
    <!--      &lt;!&ndash;          />&ndash;&gt;-->
    <!--      &lt;!&ndash;        </b-card>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </b-col>&ndash;&gt;-->
    <!--    </b-row>-->
    <!--    <div-->
    <!--      v-if="debugMode"-->
    <!--      style="height: 500px; overflow: scroll"-->
    <!--    >-->
    <!--      <pre v-if="debugMode">s-->
    <!--        {{ chartUsers }}-->
    <!--      </pre>-->
    <!--    </div>-->

  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { $themeColors } from '@themeConfig'
import { BCard } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'
import store from '@/store'
import recentPayment from '@/views/admin/temp/recentPayment.vue'
import recentBill from '@/views/admin/temp/recentBill.vue'
import miniCards from '@/views/admin/temp/dashboardComponents/MiniCards.vue'
import ChartjsComponentBarChart from '@/views/admin/temp/dashboardComponents/ChartjsComponentBarChart.vue'
import ChartjsComponentDoughnutChart from '@/views/admin/temp/dashboardComponents/ChartjsComponentDoughnutChart.vue'
import chartjsData from '@/views/admin/temp/dashboardComponents/chartjsData'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    recentPayment,
    recentBill,
    ChartjsComponentBarChart,
    ChartjsComponentDoughnutChart,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      chartjsData,
      cardTitle: 'Dashboard',
      dataGridEndpoint: [],
      module: 'dashboard',
    }
  },
  computed: {
    billFailRate() {
      if (typeof this.crmBillDashboardData.success_count === 'number' && typeof this.crmBillDashboardData.failed_count === 'number') {
        const fr = 100 - ((this.crmBillDashboardData.success_count / (this.crmBillDashboardData.success_count + this.crmBillDashboardData.failed_count)) * 100)
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(fr)) {
          return 0
        }
        return fr
      }
      return 0
    },
    billSuccessRate() {
      if (typeof this.crmBillDashboardData.success_count === 'number' && typeof this.crmBillDashboardData.failed_count === 'number') {
        const sr = (this.crmBillDashboardData.success_count / (this.crmBillDashboardData.success_count + this.crmBillDashboardData.failed_count)) * 100
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(sr)) {
          return 0
        }
        return sr
      }
      return 0
    },
    payoutFailRate() {
      if (typeof this.crmPayoutDashboardData.success_count === 'number' && typeof this.crmPayoutDashboardData.failed_count === 'number') {
        const fr = 100 - ((this.crmPayoutDashboardData.success_count / (this.crmPayoutDashboardData.success_count + this.crmPayoutDashboardData.failed_count)) * 100)
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(fr)) {
          return 0
        }
        return fr
      }
      return 0
    },
    payoutSuccessRate() {
      if (typeof this.crmPayoutDashboardData.success_count === 'number' && typeof this.crmPayoutDashboardData.failed_count === 'number') {
        const sr = (this.crmPayoutDashboardData.success_count / (this.crmPayoutDashboardData.success_count + this.crmPayoutDashboardData.failed_count)) * 100
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(sr)) {
          return 0
        }
        return sr
      }
      return 0
    },
    payoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCustomerDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        monthly_payout: 0, yesterday_payout: 0, last_week_payout: 0, today_payout: 0, last_month_payout: 0, year_payout: 0, last_year_payout: 0, total_payout: 0,
      }
    },
    crmPayoutDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCrmPayoutDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        pending_count: 0,
        success_count: 0,
        failed_count: 0,
      }
    },

    adminRecentBillsMinuteData() {
      const billsMinute = this.$store.getters['dashboard/adminRecentBillsMinuteData']
      if (billsMinute) {
        return billsMinute.result
      }
      return {

      }
    },
    adminRecentPayoutMinuteData() {
      const payoutMinute = this.$store.getters['dashboard/adminRecentPayoutMinuteData']
      if (payoutMinute) {
        return payoutMinute.result
      }
      return {

      }
    },

    crmBillDashboardData() {
      const payoutDashboardData = this.$store.getters['dashboard/adminCrmBillDashboardData']
      if (payoutDashboardData) {
        return payoutDashboardData
      }
      return {
        pending_count: 0,
        success_count: 0,
        failed_count: 0,
      }
    },
    DashboardData() {
      const list = this.$store.getters['dashboard/adminDashboardData']
      if (list !== null) {
        return list
      }
      return {
        total_user: 0,
        total_account: 0,
        total_collection: 0,
        total_bills: 0,
        total_account_inactive: 0,
        total_account_activated: 0,
        total_bill_paid: 0,
        total_bill_pending: 0,
        all_time_successful_payout: 0,
        all_time_successful_collection: 0,
      }
    },
    chartBillPaidDonut() {
      const list = this.$store.getters['dashboard/adminDashboardData']
      if (list) {
        return {
          datasets: [
            {
              labels: ['Pending', 'Paid'],
              data: [this.DashboardData.total_bill_pending, this.DashboardData.total_bill_paid],
              backgroundColor: ['#28dac6', '#28abda', $themeColors.primary],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        }
      } return {
        datasets: [
          {
            labels: ['Pending', 'Paid'],
            data: [0, 0],
            backgroundColor: ['#28dac6', '#28abda', $themeColors.primary],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      }
    },
    chartUsersDonut() {
      const list = this.$store.getters['dashboard/adminDashboardData']
      if (list) {
        return {
          datasets: [
            {
              labels: ['Active', 'Inactive'],
              data: [this.DashboardData.total_account_activated, this.DashboardData.total_account_inactive],
              backgroundColor: ['#28dac6', '#28abda', $themeColors.primary],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        }
      } return {
        datasets: [
          {
            labels: ['Active', 'Inactive'],
            data: [0, 0],
            backgroundColor: ['#28dac6', '#28abda', $themeColors.primary],
            borderWidth: 0,
            pointStyle: 'rectRounded',
          },
        ],
      }
    },
    chartBillPaid() {
      // const list = this.$store.getters['dashboard/adminDasboardChart']('area')
      const list = this.$store.getters['dashboard/adminDashboardData']

      if (list.bill_paid_list) {
        return {
          labels: this.processLabel(list.bill_paid_list),
          datasets: [{
            label: 'Bill Paid',
            data: this.processCount(list.bill_paid_list),
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
            _meta: {},
          }, {
            label: 'Bill Pending',
            data: this.processCount(list.bill_pending_list),
            backgroundColor: '#28abda',
            borderColor: 'transparent',
            _meta: {},
          }],
        }
      }
      return {
        labels: [],
        datasets: [{
          data: [], backgroundColor: '#28dac6', borderColor: 'transparent', _meta: {},
        }],
      }
      // return series
    },
    chartUsers() {
      // const list = this.$store.getters['dashboard/adminDasboardChart']('area')
      const list = this.$store.getters['dashboard/adminDashboardData']

      if (list.account_active_list) {
        return {
          labels: this.processLabel(list.account_active_list),
          datasets: [{
            label: 'Account Active',
            data: this.processCount(list.account_active_list),
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
            _meta: {},
          }, {
            label: 'Account Inactive',
            data: this.processCount(list.account_pending_list),
            backgroundColor: '#2866da',
            borderColor: 'transparent',
            _meta: {},
          }],
        }
      }
      return {
        labels: [],
        datasets: [{
          data: [], backgroundColor: '#28dac6', borderColor: 'transparent', _meta: {},
        }],
      }
      // return series
    },
  },
  async mounted() {
    const breadcrumbPayload = [
      { title: 'Dashboard' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbPayload)

    await store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)

    const payloadCrm = {
      start_date: DateTime.now().toFormat('dd-MM-yyyy'),
      end_date: DateTime.now().toFormat('dd-MM-yyyy'),
    }
    this.$store.dispatch('dashboard/getCrmReportingPayout', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })
    this.$store.dispatch('dashboard/getCrmReportingBill', payloadCrm).then(res => {
      console.log('res.data', res.data.data)
    })
    // this.$store.dispatch('dashboard/getRecentCustomerBillsMinute', payloadCrm).then(res => {
    //   console.log('res.data', res.data.data)
    // })
    // this.$store.dispatch('dashboard/getRecentCustomerPayoutMinute', payloadCrm).then(res => {
    //   console.log('res.data', res.data.data)
    // })
    const payload = {
      skip: 0,
      limit: 50,
    }

    await this.$store.dispatch(`${this.module}/getAdminDashboard`, payload)
  },
  methods: {
    processCount(obj) {
      const x = []
      if (obj) {
        obj.forEach(item => {
          x.push(item.y_axis)
        })
        console.log(x)
        return x
      }
      return []
    },
    getChartData() {
      const list = this.$store.getters['dashboard/activeChartData'](this.$route.params.type)
      if (list) {
        return list
      }
      return []
    },
    processLabel(obj) {
      const x = []
      obj.forEach(item => {
        x.push(item.x_axis)
      })
      console.log(x)
      return x
    },
  },
}
</script>

<style>

</style>
