<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <template v-if="IS_ADMIN_PORTAL">
              <b-img
                width="200px"
                :src="logoImg"
              />
            </template>
            <template v-if="IS_CRM_PORTAL">
              <b-img
                width="200px"
                :src="logoCrmImg"
              />
            </template></b-link>

          <h5 class="text-center mb-3">
            Forgot Password
          </h5>
          <!-- form -->
          <validation-observer
            ref="loginValidation"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >

              <!-- email -->
              <b-form-group
                label-for="email"
                label="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                class="mt-4"
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
                @click="validationForm"
              >
                Send me recovery email
              </b-button>
              <b-button
                variant="secondary"
                block
                @click="returnToLogin"
              >
                Back to Login
              </b-button>
            </b-form>
          </validation-observer>

        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@core/utils/validations/validations'
import store from '@/store/index'
import Router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',

      logoImg: require('@/assets/leanpay/logo_payright_blue.png'),
      logoCrmImg: require('@/assets/leanpay/logo_crm.png'),
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    jsonToFormData(data) {
      const formData = new FormData()

      this.buildFormData(formData, data)

      return formData
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
      }
    },
    returnToLogin() {
      this.$router.replace('/login')
    },
    validationForm() {
      const payload = {
        username: this.userEmail,
        password: this.password,
      }
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/getAdminAccessToken', this.jsonToFormData(payload)).then(res => {
            if (res.data.response_code === 2000) {
              this.$store.dispatch('accounts/getAccountMe').then(res2 => {
                this.accounts = res2.data.data.list.data
                useJwt.setAccountId(this.accounts[0].id)
                useJwt.setCurrentAccountObj(JSON.stringify(this.accounts)) // TODO: MOVE THIS TO SEPARATE MANAGEMENT
              })

              Router.replace('/')
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
