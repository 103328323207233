import axiosIns from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import { APP_SETTINGS } from '@/common/config'
import SystemHelper from '@/common/systemHelper'

export const GET_ONBOARD_CONSTANTS = 'getOnboardConstants'
export const SET_ONBOARD_CONSTANTS = 'setOnboardConstants'

export const GET_LIST_OF_MERCHANTS = 'getListOfMerchants'
export const SET_LIST_OF_MERCHANTS = 'setListOfMerchants'

export const GET_PAYOUT_BANK_CHANNEL_LIST = 'getPayoutBankChannelList'
export const SET_PAYOUT_BANK_CHANNEL_LIST = 'setPayoutBankChannelList'

export const GET_LIST_OF_REGISTERED_MERCHANT = 'getListOfRegisteredMerchants'
export const SET_LIST_OF_REGISTERED_MERCHANT = 'setListOfRegisteredMerchants'

export const GET_SERVICE_AUDIT_LIST = 'getServiceAuditList'
export const SET_SERVICE_AUDIT_LIST = 'setServiceAuditList'

export const GET_LIST_OF_REGISTERED_MERCHANT_OVERSEAS = 'getListOfRegisteredMerchantsOverseas'
export const SET_LIST_OF_REGISTERED_MERCHANT_OVERSEAS = 'setListOfRegisteredMerchantsOverseas'

export const GET_REFERRER_MERCHANT_LIST = 'getReferrerMerchantList'
export const SET_REFERRER_MERCHANT_LIST = 'setReferrerMerchantList'

export const GET_SANDBOX_ACCOUNT_FOR_ADMIN = 'getSandboxAccountForAdmin'
export const SET_SANDBOX_ACCOUNT_FOR_ADMIN = 'setSandboxAccountForAdmin'

export const POST_ONBOARDING_SUBMISSION_DETAIL_FOR_ADMIN = 'postOnboardingSubmissionDetailForAdmin'
export const SUBMIT_ONBOARDING_SUBMISSION_FOR_ADMIN = 'submitOnboardingSubmissionForAdmin'

export const POST_ONBOARDING_SUBMISSION_DETAIL_FOR_ADMIN_OVERSEAS = 'postOnboardingSubmissionDetailForAdminOverseas'
export const SUBMIT_ONBOARDING_SUBMISSION_FOR_ADMIN_OVERSEAS = 'submitOnboardingSubmissionForAdminOverseas'

export const ONBOARDING_APPROVE_MERCHANT_SUBMISSION_DETAIL = 'onboardingApproveMerchantSubmissionDetail'
export const ONBOARDING_REJECT_MERCHANT_SUBMISSION_DETAIL = 'onboardingRejectMerchantSubmissionDetail'

export const UPLOAD_MERCHANT_AGREEMENT = 'uploadMerchantAgreement'
export const UPLOAD_MERCHANT_DOCUMENT_ON_BEHALF = 'uploadMerchantDocumentsOnBehalf'

export const ONBOARDING_APPROVE_MERCHANT = 'onboardingApproveMerchant'
export const ONBOARDING_REJECT_MERCHANT_AGREEMENT = 'onboardingRejectMerchantAgreement'

export const SEND_EMAIL_VERIFICATION_LINK_VIA_ADMIN = 'sendEMailVerificationLinkViaAdmin'

export const CREATE_MAM_MERCHANT_USER_REFERRAL_BY_ADMIN = 'createMamMerchantUserReferralByAdmin'
export const CREATE_MAM_MERCHANT_USER = 'createMamMerchantUser'
export const POST_ONBOARDING_REFERRAL_PLAN_SETTINGS_ADMIN = 'submitOnboardingReferralPlanSettingsAdmin'
export const SUBMIT_ONBOARDING_SUBMISSION_MALAYSIA_AGREEMENT_FOR_ADMIN = 'submitOnboardingAgreementMalaysiaForAdmin'
export const SUBMIT_ONBOARDING_SUBMISSION_OVERSEA_AGREEMENT_FOR_ADMIN = 'submitOnboardingAgreementOverseaForAdmin'

const ONBOARD_API_SEGMENT = '/api/v1/env'
const ONBOARD_MOBILE_SEGMENT = '/mobile'
const ONBOARD_SYSTEM_TYPE = '/superadmin'

console.log(APP_SETTINGS.ONBOARD_BASE_URL)

export default {
  namespaced: true,
  state: {
    onboardingv2List: null,
    onboardingv2ListLocal: null,
    onboardingv2ListOversea: null,
    userSandboxAccount: null,
    serviceAuditList: null,
    onboardingv2SelectedUserDetail: null,
    onboardingConstants: null,
    payoutBankChannelList: null,
    referrerMerchantList: null,
  },
  getters: {
    getPortalOnboardingList: state => {
      const currentState = state.onboardingv2List

      if (currentState) {
        const list = currentState
        return list.data
      }
      return []
    },
    getPayoutBankChannelList: state => {
      const currentState = state.payoutBankChannelList

      if (currentState) {
        const list = currentState
        return list
      }
      return []
    },
    getOnboardConstantsByKey: state => key => {
      const obj = state.onboardingConstants
      if (obj) {
        const list = obj
        return list.data[key]
      }
      return []
    },
    getUserSandboxAccount: state => {
      const currentState = state.userSandboxAccount

      if (currentState) {
        const list = currentState
        return list.data
      }
      return []
    },
    getServiceAuditListMeta: state => {
      const currentState = state.serviceAuditList

      if (currentState) {
        const list = currentState
        return list
      }
      return []
    },
    getServiceAuditList: state => {
      const currentState = state.serviceAuditList

      if (currentState) {
        const list = currentState
        return list.data
      }
      return []
    },
    getPortalOnboardingListLocal: state => {
      const currentState = state.onboardingv2ListLocal

      if (currentState) {
        const list = currentState
        return list.data.data
      }
      return []
    },

    getPortalOnboardingListLocalMeta: state => {
      const currentState = state.onboardingv2ListLocal

      if (currentState) {
        const duplicateQ = { ...currentState.data }
        delete duplicateQ.data
        return duplicateQ
      }
      return []
    },
    getReferrerMerchantListOpts: state => {
      const currentState = state.referrerMerchantList

      if (currentState) {
        return currentState
      }
      return []
    },
    getPortalOnboardingListOversea: state => {
      const currentState = state.onboardingv2ListOversea

      if (currentState) {
        const list = currentState
        return list.data.data
      }
      return []
    },
    getPortalOnboardingListOverseaMeta: state => {
      const currentState = state.onboardingv2ListOversea

      if (currentState) {
        const duplicateQ = { ...currentState.data }
        delete duplicateQ.data
        return duplicateQ
      }
      return []
    },
  },
  mutations: {
    [SET_LIST_OF_MERCHANTS](state, val) {
      state.onboardingv2List = val
    },
    [SET_ONBOARD_CONSTANTS](state, val) {
      state.onboardingConstants = val
    },
    [SET_PAYOUT_BANK_CHANNEL_LIST](state, val) {
      state.payoutBankChannelList = val
    },
    [SET_REFERRER_MERCHANT_LIST](state, val) {
      state.referrerMerchantList = val
    },
    [SET_SANDBOX_ACCOUNT_FOR_ADMIN](state, val) {
      state.userSandboxAccount = val
    },
    [SET_SERVICE_AUDIT_LIST](state, val) {
      state.serviceAuditList = val
    },
    [SET_LIST_OF_REGISTERED_MERCHANT](state, val) {
      state.onboardingv2ListLocal = val
    },
    [SET_LIST_OF_REGISTERED_MERCHANT_OVERSEAS](state, val) {
      state.onboardingv2ListOversea = val
    },
  },
  actions: {

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/getSystemContants
    [GET_ONBOARD_CONSTANTS](context) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/getSystemContants`)
          .then(res => {
            if (res.data.response_code === 2100) {
              context.commit(SET_ONBOARD_CONSTANTS, res.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/GetPayoutBankChannelListAdmin
    [GET_PAYOUT_BANK_CHANNEL_LIST](context) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/GetPayoutBankChannelListAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              context.commit(SET_PAYOUT_BANK_CHANNEL_LIST, res.data.data.payout_service_list)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ListOfMerchants
    [GET_LIST_OF_MERCHANTS](context, payload) {
      const defaultPayload = {
        length: 100,
        start: 0,
        mam_identifier: 'PAYRIGHT',
        filter_array_objects: JSON.stringify([{ filter_column: 'created_at', filter_start: '1997-02-01', filter_end: '2045-02-27' }]),
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ListOfMerchants`, defaultPayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              context.commit(SET_LIST_OF_MERCHANTS, res.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ListOfRegisteredMerchants
    [GET_LIST_OF_REGISTERED_MERCHANT](context, payload) {
      const defaultPayload = {
        length: 100,
        start: 0,
        mam_identifier: 'PAYRIGHT',
        filter_array_objects: JSON.stringify([{ filter_column: 'created_at', filter_start: '1997-02-01', filter_end: '2045-02-27' }]),
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ListOfRegisteredMerchants`, defaultPayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              context.commit(SET_LIST_OF_REGISTERED_MERCHANT, res.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/PostOnboardingSubmissionDetailForAdmin
    [POST_ONBOARDING_SUBMISSION_DETAIL_FOR_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/PostOnboardingSubmissionDetailForAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/SendEmailVerificationLinkForAdmin
    [SEND_EMAIL_VERIFICATION_LINK_VIA_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/SendEmailVerificationLinkForAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/PostOnboardingReferralAndPlanSettings
    [POST_ONBOARDING_REFERRAL_PLAN_SETTINGS_ADMIN](context, payload) {
      return new Promise((resolve, reject) => {
        const fpayload = {
          mam_identifier: 'PAYRIGHT',
          ...payload,
        }
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/PostOnboardingReferralAndPlanSettings`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/SubmitOnboardingSubmissionForAdminMalaysia
    [SUBMIT_ONBOARDING_SUBMISSION_FOR_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/SubmitOnboardingSubmissionForAdminMalaysia`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/SubmitOnboardingSubmissionMalaysiaAgreementForAdmin
    [SUBMIT_ONBOARDING_SUBMISSION_MALAYSIA_AGREEMENT_FOR_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/SubmitOnboardingSubmissionMalaysiaAgreementForAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/SubmitOnboardingSubmissionOverseasAgreementForAdmin
    [SUBMIT_ONBOARDING_SUBMISSION_OVERSEA_AGREEMENT_FOR_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/SubmitOnboardingSubmissionOverseasAgreementForAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ListOfRegisteredMerchantsOverseas
    [GET_LIST_OF_REGISTERED_MERCHANT_OVERSEAS](context, payload) {
      const defaultPayload = {
        length: 100,
        start: 0,
        mam_identifier: 'PAYRIGHT',
        filter_array_objects: JSON.stringify([{
          filter_column: 'created_at',
          filter_start: '1997-02-01',
          filter_end: '2045-02-27',
        }]),
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ListOfRegisteredMerchantsOverseas`, defaultPayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              context.commit(SET_LIST_OF_REGISTERED_MERCHANT_OVERSEAS, res.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/CreateMamMerchantUserThroughReferralByAdmin
    [CREATE_MAM_MERCHANT_USER_REFERRAL_BY_ADMIN](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/CreateMamMerchantUserThroughReferralByAdmin`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              // useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/CreateMamMerchantUser
    [CREATE_MAM_MERCHANT_USER](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/CreateMamMerchantUser`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              // useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/GetReferrerMerchantList
    [GET_REFERRER_MERCHANT_LIST](context, payload) {
      const defaultPayload = {
        length: 100,
        start: 0,
        mam_identifier: 'PAYRIGHT',
        filter_array_objects: JSON.stringify([{
          filter_column: 'created_at',
          filter_start: '1997-02-01',
          filter_end: '2045-02-27',
        }]),
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/GetReferrerMerchantList`, defaultPayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              // useJwt.setToken(res.data.token)
              context.commit(SET_REFERRER_MERCHANT_LIST, res.data.data.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/PostOnboardingSubmissionDetailForAdminOverseas
    [POST_ONBOARDING_SUBMISSION_DETAIL_FOR_ADMIN_OVERSEAS](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/PostOnboardingSubmissionDetailForAdminOverseas`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/SubmitOnboardingSubmissionForAdminOverseas
    [SUBMIT_ONBOARDING_SUBMISSION_FOR_ADMIN_OVERSEAS](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/SubmitOnboardingSubmissionForAdminOverseas`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ApproveMerchant
    [ONBOARDING_APPROVE_MERCHANT](context, payload) {
      const fpayload = {
        mam_identifier: 'PAYRIGHT',
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ApproveMerchant`, fpayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/UploadMerchantAgreement
    [UPLOAD_MERCHANT_AGREEMENT](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/UploadMerchantAgreement`, payload, config)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/UploadDocumentByAdmin
    [UPLOAD_MERCHANT_DOCUMENT_ON_BEHALF](context, payload) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/UploadDocumentByAdmin`, payload, config)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/RejectMerchantAgreement
    [ONBOARDING_REJECT_MERCHANT_AGREEMENT](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/RejectMerchantAgreement`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ApproveMerchantSubmissionDetail
    [ONBOARDING_APPROVE_MERCHANT_SUBMISSION_DETAIL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ApproveMerchantSubmissionDetail`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },
    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/RejectMerchantSubmissionDetail
    [ONBOARDING_REJECT_MERCHANT_SUBMISSION_DETAIL](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/RejectMerchantSubmissionDetail`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/GetSandboxAccountForAdmin
    [GET_SANDBOX_ACCOUNT_FOR_ADMIN](context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/GetSandboxAccountForAdmin`, payload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              context.commit(SET_SANDBOX_ACCOUNT_FOR_ADMIN, res.data.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

    // https://stag.switch.leanpay.my/api/v1/env/mobile/superadmin/ServiceAuditList
    [GET_SERVICE_AUDIT_LIST](context, payload) {
      const defaultPayload = {
        length: 50,
        start: 0,
        ...payload,
      }
      return new Promise((resolve, reject) => {
        axiosIns.post(`${APP_SETTINGS.ONBOARD_BASE_URL + ONBOARD_API_SEGMENT + ONBOARD_MOBILE_SEGMENT + ONBOARD_SYSTEM_TYPE}/ServiceAuditList`, defaultPayload)
          .then(res => {
            if (res.data.response_code === 2100) {
              useJwt.setToken(res.data.token)
              context.commit(SET_SERVICE_AUDIT_LIST, res.data.data)
              // SystemHelper.successHandler(res)
              resolve(res)
            } else {
              // SystemHelper.errorHandler(res)
              resolve(res)
            }
          })
          .catch(err => {
            SystemHelper.handleCatch()
            reject(err)
          })
      })
    },

  },

}
